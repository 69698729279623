import {useState, useEffect} from 'react'
import Header from '../compunet/Header'
import Footer from '../compunet/Footer'
import { Link } from 'react-router-dom'
import BaseUrl from "../baseurl/BaseUrl";
// import { PostApiWithOutToken } from "../Helper/helper";
import { getApiWithToken } from "../Helper/helper";



const Surrogatedatabase = () => {
  useEffect(() => {
    
    window.scrollTo(0, 0);
  
    getData(); // Assuming getData is a function defined outside useEffect
    // getDatas();
    // statusll("pending")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let parentData=""
        const parentDataString = localStorage.getItem("parentdata");
if (parentDataString) {
   parentData = JSON.parse(parentDataString);
  console.log(parentData);
} else {
  console.log("No data found in localStorage.");
}
console.log(parentData);
  const [userData,] = useState(localStorage.getItem("tokenparent"));
//   console.log(userData);
//   const [datas, setdatas] = useState()
  const [dataall, setdataall] = useState()

  const getData = () => {
    // setLoader(true)
    getApiWithToken(`${BaseUrl.baseUrl}parent/surrogate`, "", userData)
      .then(({ data }) => {
        console.log("minhaj", data);
        console.log("minhaj", data.surrogates);

        // setdatas(data)
        setdataall(data.surrogates)
        // console.log(datas);

        console.log(dataall);

        // setfirst_detail(data.home?.first_detail)
        // setsecond_detail(data.home?.second_detail)
        // setthird_detail(data.home?.third_detail)
        // setfour_detail(data.home?.four_detail)
        // setfifth_detail(data.home?.fifth_detail)
         
        
        // setLoader(false)
      })
      .catch((err) => {
        console.log("err---------", err);
      });


};
  return (
    <>
      <body className="moto-background moto-website_live">
        <div className="page">
          <Header />
          <section
            id="section-content"
            className="content page-1 moto-section"
            data-widget="section"
            data-container="section"
          >
            <div
              id="wid_1595841006_s9ff8o2hk"
              className="moto-widget moto-widget-content_slider moto-widget_interactive moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto   moto-widget-content_slider-controls-visible-on_tablet_hidden moto-widget-content_slider-controls-visible-on_mobile-h_hidden moto-widget-content_slider-controls-visible-on_mobile-v_hidden moto-widget__state_loading"
              data-widget="content_slider"
              data-preset="default"
              data-moto-content-slider='{"preferences":{"startAnimation":"onArriving"},"options":{"auto":true,"pause":3000,"mode":"fade","controls":true,"pager":false,"autoControls":false}}'
            >
              <div
                id="wid_1595841006_s9ff8o2hk__loader"
                className=""
              >
                <div className="moto-widget-loader__indicator" />
              </div>
             
            </div>

        
            <div
              className="moto-widget moto-widget-row row-fixed row-gutter-0 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
              data-grid-type="sm"
              data-widget="row"
              data-visible-on="-"
              data-spacing="aaaa"
              style={{}}
              data-bg-position="left top"
            >
                <div className="">
                                <div
                                    id="wid_1595841006_s9ff8o2hk__content"
                                    className="moto-widget__content"
                                >
                                    <div
                                        id="wid_1595841023_rrwvo0wxl"
                                        data-parent-id="wid_1595841006_s9ff8o2hk"
                                        className="moto-widget moto-widget-content_slider__item moto-widget-row moto-widget_with-content-editor  row-fixed moto-disabling_row-fixed moto-entertainment__playing_disabled moto-before-in-viewport_content-invisible"
                                        style={{
                                            backgroundPosition: "top",
                                            backgroundImage:
                                                "url(mt-demo/109600/109604/mt-content/uploads/2020/07/mt-2068_home_bg03.jpg)",
                                            backgroundSize: "cover",
                                            backgroundRepeat: "no-repeat"
                                        }}
                                        data-widget="content_slider.item"
                                        data-preset=""
                                    >
                                        <div className="moto-widget__content-wrapper container-fluid">
                                            <div className="moto-widget__content row">
                                                <div
                                                    id="wid_1595841023_rrwvo0wxl__content"
                                                    className="moto-cell col-sm-12 moto-entertainment__animation_disabled moto-entertainment__animation_ignore-visibility"
                                                >
                                                    <div
                                                        data-widget-id="wid_1595841057_t4stgm9px"
                                                        className="moto-widget moto-widget-row moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto  "
                                                        data-grid-type="sm"
                                                        data-widget="row"
                                                        data-visible-on="+desktop,tablet,mobile-h,mobile-v"
                                                        data-spacing="lala"
                                                        style={{ backgroundPosition: "left top" }}
                                                    >
                                                        <div className="container-fluid">
                                                            <div className="row" data-container="container">
                                                                <div
                                                                    data-widget-id="wid_1595841057_89be3r1sr"
                                                                    className="moto-widget moto-widget-row__column moto-cell  col-sm-12 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                                                                    style={{ backgroundPosition: "left top" }}
                                                                    data-widget="row.column"
                                                                    data-container="container"
                                                                    data-spacing="aaaa"
                                                                >
                                                                    <div
                                                                        data-widget-id="wid_1595841837_ubu65qgf0"
                                                                        className="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto moto-visible-on_tablet_hidden moto-visible-on_mobile-h_hidden moto-visible-on_mobile-v_hidden"
                                                                        data-widget="spacer"
                                                                        data-preset="default"
                                                                        data-spacing="laaa"
                                                                        data-visible-on="+desktop"
                                                                    >
                                                                        <div
                                                                            className="moto-widget-spacer-block"
                                                                            style={{ height: 0 }}
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        data-widget-id="wid_1595841066_6joo2ll8u"
                                                                        className="moto-widget moto-widget-text moto-preset-default  moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto  "
                                                                        data-widget="text"
                                                                        data-preset="default"
                                                                        data-spacing="aaaa"
                                                                        data-visible-on="+desktop,tablet,mobile-h,mobile-v"
                                                                        data-animation=""
                                                                    >
                                                                        <div className="moto-widget-text-content moto-widget-text-editable">
                                                                            {/* <p className="moto-text_system_5">
                        WE HAVE A SET OF
                      </p> */}
                                                                            {/* <h1 className="moto-text_system_3">
                        SERVICES TO HELP
                      </h1> */}
                                                                            <p className="moto-text_system_4">
                                                                              Surrogate Database
                                                                            
                                                                              {/* {datas?.title_1} */}
                                                                                {/* THE SURROGACY PROGRAM AT PSC */}
                                                                            </p>

                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        data-widget-id="wid_1595841737_olwfxtvqo"
                                                                        className="moto-widget moto-widget-row moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto  "
                                                                        data-grid-type="sm"
                                                                        data-widget="row"
                                                                        data-visible-on="+desktop,tablet,mobile-h,mobile-v"
                                                                        data-spacing="mama"
                                                                        style={{ backgroundPosition: "left top" }}
                                                                    >
                                                                        <div className="container-fluid">
                                                                            <div className="row" data-container="container">
                                                                                <div
                                                                                    data-widget-id="wid_1595841737_64k6q85ws"
                                                                                    className="moto-widget moto-widget-row__column moto-cell  col-sm-7 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                                                                                    style={{ backgroundPosition: "left top" }}
                                                                                    data-widget="row.column"
                                                                                    data-container="container"
                                                                                    data-spacing="aaaa"
                                                                                >
                                                                                    <div
                                                                                        data-widget-id="wid_1595841752_3xt78tb1c"
                                                                                        className="moto-widget moto-widget-text moto-preset-default  moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto  "
                                                                                        data-widget="text"
                                                                                        data-preset="default"
                                                                                        data-spacing="aaaa"
                                                                                        data-visible-on="+desktop,tablet,mobile-h,mobile-v"
                                                                                        data-animation=""
                                                                                    >
                                                                                        {/* <div className="moto-widget-text-content moto-widget-text-editable">
                              <p className="moto-text_211">
                                Starting with a consultation on what
                                choices you'll have as a future
                                parent, and all the way to group
                                support classes and medical exams,
                                we're here to help!
                              </p>
                            </div> */}
                                                                                    </div>
                                                                                </div>
                                                                                {/* <div
                          data-widget-id="wid_1595841739_yync027fc"
                          className="moto-widget moto-widget-row__column moto-cell  col-sm-5 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                          style={{ backgroundPosition: "left top" }}
                          data-widget="row.column"
                          data-container="container"
                          data-spacing="aaaa"
                        ></div> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/* <div
                    data-widget-id="wid_1595841278_2xqb0ppjw"
                    className="moto-widget moto-widget-button moto-preset-default moto-preset-provider-default moto-align-left moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto  "
                    data-widget="button"
                  >
                    <a
                      // href="#"
                      data-action="popup"
                      data-popup-id={8}
                      className="moto-widget-button-link moto-size-small moto-link"
                    >
                      <span className="fa moto-widget-theme-icon" />
                      <span className="moto-widget-button-divider" />
                      <span className="moto-widget-button-label">
                        Make an appointment&nbsp;
                      </span>
                    </a>
                  </div> */}
                                                                    <div
                                                                        data-widget-id="wid_1595841846_f2kcxi7k6"
                                                                        className="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto moto-visible-on_tablet_hidden moto-visible-on_mobile-h_hidden moto-visible-on_mobile-v_hidden"
                                                                        data-widget="spacer"
                                                                        data-preset="default"
                                                                        data-spacing="laaa"
                                                                        data-visible-on="+desktop"
                                                                    >
                                                                        <div
                                                                            className="moto-widget-spacer-block"
                                                                            style={{ height: 0 }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div
id="wid_1595841927_bdfvoucbl"
data-parent-id="wid_1595841006_s9ff8o2hk"
className="moto-widget moto-widget-content_slider__item moto-widget-row moto-widget_with-content-editor  row-fixed moto-disabling_row-fixed moto-entertainment__playing_disabled moto-before-in-viewport_content-invisible"
style={{
backgroundPosition: "top",
backgroundImage:
  "url(mt-demo/109600/109604/mt-content/uploads/2020/07/mt-2068_home_slider02.jpg)",
backgroundSize: "cover",
backgroundRepeat: "no-repeat"
}}
data-widget="content_slider.item"
data-preset=""
>
<div className="moto-widget__content-wrapper container-fluid">
<div className="moto-widget__content row">
  <div
    id="wid_1595841927_bdfvoucbl__content"
    className="moto-cell col-sm-12 moto-entertainment__animation_disabled moto-entertainment__animation_ignore-visibility"
  >
    <div
      data-widget-id="wid_1595841927_7x5uw1q2y"
      className="moto-widget moto-widget-row moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto  "
      data-grid-type="sm"
      data-widget="row"
      data-visible-on="+desktop,tablet,mobile-h,mobile-v"
      data-spacing="lala"
      style={{ backgroundPosition: "left top" }}
    >
      <div className="container-fluid">
        <div className="row" data-container="container">
          <div
            data-widget-id="wid_1595841927_zr67jwsfa"
            className="moto-widget moto-widget-row__column moto-cell  col-sm-12 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
            style={{ backgroundPosition: "left top" }}
            data-widget="row.column"
            data-container="container"
            data-spacing="aaaa"
          >
            <div
              data-widget-id="wid_1595841927_gny5i9ikv"
              className="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto moto-visible-on_tablet_hidden moto-visible-on_mobile-h_hidden moto-visible-on_mobile-v_hidden"
              data-widget="spacer"
              data-preset="default"
              data-spacing="laaa"
              data-visible-on="+desktop"
            >
              <div
                className="moto-widget-spacer-block"
                style={{ height: 0 }}
              />
            </div>
            <div
              data-widget-id="wid_1595841927_vqn575vq6"
              className="moto-widget moto-widget-text moto-preset-default  moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto  "
              data-widget="text"
              data-preset="default"
              data-spacing="aaaa"
              data-visible-on="+desktop,tablet,mobile-h,mobile-v"
              data-animation=""
            >
              <div className="moto-widget-text-content moto-widget-text-editable">
                <p className="moto-text_system_5">
                  THIS WEEK ONLY!
                </p>
                <p className="moto-text_system_3">GET A FREE</p>
                <p className="moto-text_system_4">
                  CONSULTATION
                </p>
              </div>
            </div>
            <div
              data-widget-id="wid_1595841927_2eqhunz6h"
              className="moto-widget moto-widget-row moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto  "
              data-grid-type="sm"
              data-widget="row"
              data-visible-on="+desktop,tablet,mobile-h,mobile-v"
              data-spacing="mama"
              style={{ backgroundPosition: "left top" }}
            >
              <div className="container-fluid">
                <div className="row" data-container="container">
                  <div
                    data-widget-id="wid_1595841927_jkrwtgbq7"
                    className="moto-widget moto-widget-row__column moto-cell  col-sm-6 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                    style={{ backgroundPosition: "left top" }}
                    data-widget="row.column"
                    data-container="container"
                    data-spacing="aaaa"
                  >
                    <div
                      data-widget-id="wid_1595841927_pync8qvne"
                      className="moto-widget moto-widget-text moto-preset-default  moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto  "
                      data-widget="text"
                      data-preset="default"
                      data-spacing="aaaa"
                      data-visible-on="+desktop,tablet,mobile-h,mobile-v"
                      data-animation=""
                    >
                      <div className="moto-widget-text-content moto-widget-text-editable">
                        <p className="moto-text_211">
                          Only during this week we offer you a
                          free of charge consultation. The offer
                          can be applied to absolutely any type
                          of pregnancy issue you might have!
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    data-widget-id="wid_1595841927_s8yk2533l"
                    className="moto-widget moto-widget-row__column moto-cell  col-sm-6 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                    style={{ backgroundPosition: "left top" }}
                    data-widget="row.column"
                    data-container="container"
                    data-spacing="aaaa"
                  ></div>
                </div>
              </div>
            </div>
            <div
              data-widget-id="wid_1595841927_m39abk6yy"
              className="moto-widget moto-widget-button moto-preset-default moto-preset-provider-default moto-align-left moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto  "
              data-widget="button"
            >
              <a
                href="#"
                data-action="popup"
                data-popup-id={8}
                className="moto-widget-button-link moto-size-small moto-link"
              >
                <span className="fa moto-widget-theme-icon" />
                <span className="moto-widget-button-divider" />
                <span className="moto-widget-button-label">
                  Make an appointment&nbsp;
                </span>
              </a>
            </div>
            <div
              data-widget-id="wid_1595841927_5ua93h4yr"
              className="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto moto-visible-on_tablet_hidden moto-visible-on_mobile-h_hidden moto-visible-on_mobile-v_hidden"
              data-widget="spacer"
              data-preset="default"
              data-spacing="laaa"
              data-visible-on="+desktop"
            >
              <div
                className="moto-widget-spacer-block"
                style={{ height: 0 }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
</div> */}
                                    {/* <div
id="wid_1595842021_npa7vwnc1"
data-parent-id="wid_1595841006_s9ff8o2hk"
className="moto-widget moto-widget-content_slider__item moto-widget-row moto-widget_with-content-editor  row-fixed moto-disabling_row-fixed moto-entertainment__playing_disabled moto-before-in-viewport_content-invisible"
style={{
backgroundPosition: "top",
backgroundImage:
  "url(mt-demo/109600/109604/mt-content/uploads/2020/07/mt-2068_home_slider03.jpg)",
backgroundSize: "cover",
backgroundRepeat: "no-repeat"
}}
data-widget="content_slider.item"
data-preset=""
>
<div className="moto-widget__content-wrapper container-fluid">
<div className="moto-widget__content row">
  <div
    id="wid_1595842021_npa7vwnc1__content"
    className="moto-cell col-sm-12 moto-entertainment__animation_disabled moto-entertainment__animation_ignore-visibility"
  >
    <div
      data-widget-id="wid_1595842021_x52c8pbjk"
      className="moto-widget moto-widget-row moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto  "
      data-grid-type="sm"
      data-widget="row"
      data-visible-on="+desktop,tablet,mobile-h,mobile-v"
      data-spacing="lala"
      style={{ backgroundPosition: "left top" }}
    >
      <div className="container-fluid">
        <div className="row" data-container="container">
          <div
            data-widget-id="wid_1595842021_lx5dkmsgw"
            className="moto-widget moto-widget-row__column moto-cell  col-sm-12 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
            style={{ backgroundPosition: "left top" }}
            data-widget="row.column"
            data-container="container"
            data-spacing="aaaa"
          >
            <div
              data-widget-id="wid_1595842021_n6twdsc71"
              className="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto moto-visible-on_tablet_hidden moto-visible-on_mobile-h_hidden moto-visible-on_mobile-v_hidden"
              data-widget="spacer"
              data-preset="default"
              data-spacing="laaa"
              data-visible-on="+desktop"
            >
              <div
                className="moto-widget-spacer-block"
                style={{ height: 0 }}
              />
            </div>
            <div
              data-widget-id="wid_1595842021_kj3z4k0vg"
              className="moto-widget moto-widget-text moto-preset-default  moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto  "
              data-widget="text"
              data-preset="default"
              data-spacing="aama"
              data-visible-on="+desktop,tablet,mobile-h,mobile-v"
              data-animation=""
            >
              <div className="moto-widget-text-content moto-widget-text-editable">
                <p className="moto-text_system_5">
                  <span className="moto-color_custom3">
                    HAVE ACCESS TO
                  </span>
                </p>
                <p className="moto-text_system_3">A HEALTH</p>
                <p className="moto-text_system_3">
                  PROFESSIONAL
                </p>
                <p className="moto-text_system_4">
                  <span className="moto-color_custom3">
                    AT ANY TIME
                  </span>
                </p>
              </div>
            </div>
            <div
              data-widget-id="wid_1595842021_ju5ys6p8d"
              className="moto-widget moto-widget-button moto-preset-default moto-preset-provider-default moto-align-left moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto  "
              data-widget="button"
            >
              <a
                href="#"
                data-action="popup"
                data-popup-id={8}
                className="moto-widget-button-link moto-size-small moto-link"
              >
                <span className="fa moto-widget-theme-icon" />
                <span className="moto-widget-button-divider" />
                <span className="moto-widget-button-label">
                  Make an appointment&nbsp;
                </span>
              </a>
            </div>
            <div
              data-widget-id="wid_1595842021_8fugufps4"
              className="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto moto-visible-on_tablet_hidden moto-visible-on_mobile-h_hidden moto-visible-on_mobile-v_hidden"
              data-widget="spacer"
              data-preset="default"
              data-spacing="laaa"
              data-visible-on="+desktop"
            >
              <div
                className="moto-widget-spacer-block"
                style={{ height: 0 }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
</div> */}
                                </div>
                            </div>
              <div className="container-fluid" style={{marginTop:"50px"}}>
                 {/* <h3>Surrogate Database</h3> */}
                 <div className="row"  style={{marginTop:"40px",marginBottom:"20px"}} data-container="container">
                    {dataall?.map((e)=>{
return(
                
                <div
                    className="moto-widget moto-widget-row__column mt-5 moto-cell col-sm-3  moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                    style={{}}
                    data-widget="row.column"
                    data-container="container"
                    data-spacing="aaaa"
                    data-bg-position="left top"
                  >
                     <Link to={`/surrogate-detail/${e?.id}`} style={{textDecoration:"none"}}>
                    <div
                    
                      className="moto-widget moto-widget-container undefined moto-container_content_5f1ea68f5"
                      data-visible-on=""
                      data-widget="container"
                      data-container="container"
                      data-css-name="moto-container_content_5f1ea68f5"
                      data-bg-position="left top"
                    >
                      <div
                        className="moto-widget moto-widget-row moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto"
                        data-grid-type="sm"
                        data-widget="row"
                        data-visible-on="-"
                        data-spacing="lala"
                        style={{}}
                        data-bg-position="left top"
                      >
                        <div className="container-fluid">
                          <div className="row" data-container="container">
                            <div
                              className="moto-widget moto-widget-row__column moto-cell col-sm-12 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                              style={{}}
                              data-widget="row.column"
                              data-container="container"
                              data-spacing="aaaa"
                              data-bg-position="left top"
                            >
                              <div
                                data-widget-id="wid_1595844239_fbu94omck"
                                className="moto-widget moto-widget-image moto-widget_with-deferred-content moto-preset-2 moto-align-center moto-spacing-top-auto moto-spacing-right-small moto-spacing-bottom-medium moto-spacing-left-small  "
                                data-widget="image"
                              >
                                  {/* <img src="/mt-demo/109600/109604/mt-content/uploads/2020/07/mt-2068_home_img04.jpg" */}
                                <span className="moto-widget-image-link">
                                <img src={`https://pscapi.devssh.xyz/${e?.image}`}

                                    //   src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0  '%3E%3C/svg%3E"
                                    className="moto-widget-image-picture moto-widget-deferred-content lazyload"
                                    //   data-id={152}
                                    alt="hospital"
                                  />
                                </span>
                              </div>
                              <div
                                data-widget-id="wid_1595844239_ip77mk8zh"
                                className="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto moto-visible-on_tablet_hidden moto-visible-on_mobile-h_hidden moto-visible-on_mobile-v_hidden"
                                data-widget="spacer"
                                data-preset="default"
                                data-spacing="maaa"
                                data-visible-on="+desktop"
                              >
                                <div
                                  className="moto-widget-spacer-block"
                                  style={{ height: 0 }}
                                />
                              </div>
                              <div
                                className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-small moto-spacing-bottom-small moto-spacing-left-small"
                                data-widget="text"
                                data-preset="default"
                                data-spacing="asss"
                                data-visible-on="-"
                                data-animation=""
                              >
                                <div className="moto-widget-text-content moto-widget-text-editable">
                                  <h3
                                    style={{ textAlign: "center" }}
                                    className="moto-text_system_8"
                                  >
              {e?.first_name}

                                  {/* Dreams Have the Potential to Be Realized */}
                                  </h3>
                                </div>
                              </div>
                              <div
                                className="moto-widget moto-widget-row moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                                data-grid-type="xs"
                                data-widget="row"
                                data-visible-on="-"
                                data-spacing="saaa"
                                style={{}}
                                data-bg-position="left top"
                              >
                                <div className="container-fluid">
                                  <div className="row" data-container="container">
                                    <div
                                      className="moto-widget moto-widget-row__column moto-cell col-xs-1 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                                      style={{}}
                                      data-widget="row.column"
                                      data-container="container"
                                      data-spacing="aaaa"
                                      data-bg-position="left top"
                                    ></div>
                                    <div
                                      className="moto-widget moto-widget-row__column moto-cell col-xs-10 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                                      style={{}}
                                      data-widget="row.column"
                                      data-container="container"
                                      data-spacing="aaaa"
                                      data-bg-position="left top"
                                    >
                                      <div
                                        className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                                        data-widget="text"
                                        data-preset="default"
                                        data-spacing="aaaa"
                                        data-visible-on="-"
                                        data-animation=""
                                      >
                                        <div className="moto-widget-text-content moto-widget-text-editable">
                                          <p
                                            className="moto-text_normal"
                                            style={{ textAlign: "center" }}
                                          >
       {e?.email}

                                            {/* Prospective parents who seek our services need not worry about high costs. */}
                                          </p>
                                        </div>
                                        <div className='col-lg-12 d-flex justify-content-center'>
                                        <Link to={`/surrogate-detail/${e?.id}`}>

                                          <button type="button" className="btn btn-primary btn-lg"
                                            style={{
                                              border: 'none',
                                              width: '100px',
                                              height: '44px',
                                              boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                                              marginTop: '17px',

                                            }}



                                          >View</button>
                                          </Link>
                                        </div>

                                      </div>
                                    </div>
                                    <div
                                      className="moto-widget moto-widget-row__column moto-cell col-xs-1 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                                      style={{}}
                                      data-widget="row.column"
                                      data-container="container"
                                      data-spacing="aaaa"
                                      data-bg-position="left top"
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    </Link>
                    
                  </div>
                 
                  )
    })}
                </div>
              </div>
            </div>
           
            
            
            {/* <div
              className="moto-widget moto-widget-row row-fixed moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto"
              data-grid-type="sm"
              data-widget="row"
              data-visible-on="-"
              data-spacing="lala"
              style={{}}
              data-bg-position="left top"
            >
              <div className="container-fluid">
                <div className="row" data-container="container">
                  <div
                    className="moto-widget moto-widget-row__column moto-cell col-sm-12 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                    style={{}}
                    data-widget="row.column"
                    data-container="container"
                    data-spacing="aaaa"
                    data-bg-position="left top"
                  >
                    <div
                      className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                      data-widget="text"
                      data-preset="default"
                      data-spacing="saaa"
                      data-visible-on="-"
                      data-animation=""
                      data-draggable-disabled=""
                    >
                      <div
                        className="moto-widget-text-content moto-widget-text-editable"
                        aria-multiline="true"
                      >
                        <h2
                          className="moto-text_system_6"
                          style={{ textAlign: "center" }}
                        >
                          <span className="moto-color1_1">TIPS FOR PREGNANT</span>
                        </h2>
                      </div>
                    </div>
                    <div
                      className="moto-widget moto-widget-row moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                      data-grid-type="sm"
                      data-widget="row"
                      data-visible-on="-"
                      data-spacing="maaa"
                      style={{}}
                      data-bg-position="left top"
                      data-draggable-disabled=""
                    >
                      <div className="container-fluid">
                        <div className="row" data-container="container">
                          <div
                            className="moto-widget moto-widget-row__column moto-cell col-sm-6 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                            style={{}}
                            data-widget="row.column"
                            data-container="container"
                            data-spacing="aaaa"
                            data-bg-position="left top"
                          >
                            <div
                              data-widget-id="wid_1595848666_4cdic4qqs"
                              className="moto-widget moto-widget-image moto-widget_with-deferred-content moto-preset-default moto-align-center moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto  "
                              data-widget="image"
                            >
                              <span className="moto-widget-image-link">
                                <img
                                  data-src="/mt-demo/109600/109604/mt-content/uploads/2020/07/mt-2068_home_img05.jpg"
                                  src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0  '%3E%3C/svg%3E"
                                  className="moto-widget-image-picture moto-widget-deferred-content lazyload"
                                  data-id={163}
                                  title=""
                                  alt=""
                                />
                              </span>
                            </div>
                            <div
                              className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto"
                              data-widget="text"
                              data-preset="default"
                              data-spacing="sama"
                              data-visible-on="-"
                              data-animation=""
                            >
                              <div className="moto-widget-text-content moto-widget-text-editable">
                                <h3 className="moto-text_203">
                                  <a
                                    href="#"
                                    target="_self"
                                    data-action="url"
                                    className="moto-link"
                                  >
                                    VITAMINS FOR PREGNANT WOMEN
                                  </a>
                                </h3>
                                <p className="moto-text_normal">&nbsp;</p>
                                <p className="moto-text_normal">
                                  Yep, contrary to some widespread false belief that
                                  women who are pregnant should just stay at home and
                                  lie on the couch idly, the importance of a healthy
                                  dose of physical activity for moms-to-be cannot be
                                  overestimated, really...
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="moto-widget moto-widget-row__column moto-cell col-sm-6 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                            style={{}}
                            data-widget="row.column"
                            data-container="container"
                            data-spacing="aaaa"
                            data-bg-position="left top"
                          >
                            <div
                              data-widget-id="wid_1595848788_e3sr2wq70"
                              className="moto-widget moto-widget-image moto-widget_with-deferred-content moto-preset-default moto-align-center moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto  "
                              data-widget="image"
                            >
                              <span className="moto-widget-image-link">
                                <img
                                  data-src="/mt-demo/109600/109604/mt-content/uploads/2020/07/mt-2068_home_img06.jpg"
                                  src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0  '%3E%3C/svg%3E"
                                  className="moto-widget-image-picture moto-widget-deferred-content lazyload"
                                  data-id={164}
                                  title=""
                                  alt=""
                                />
                              </span>
                            </div>
                            <div
                              className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto"
                              data-widget="text"
                              data-preset="default"
                              data-spacing="sama"
                              data-visible-on="-"
                              data-animation=""
                              data-draggable-disabled=""
                            >
                              <div
                                className="moto-widget-text-content moto-widget-text-editable"
                                aria-multiline="true"
                              >
                                <h3 className="moto-text_203">
                                  <a
                                    href="#"
                                    target="_self"
                                    data-action="url"
                                    className="moto-link"
                                  >
                                    EXERCISING FOR FUTURE MOMS
                                  </a>
                                </h3>
                                <p className="moto-text_normal">
                                  <br />
                                </p>
                                <p className="moto-text_normal">
                                  Just as it goes with everything else, knowing about
                                  the situation you're caught into is useful. And the
                                  more you know on any given subject (which in our
                                  case is a pregnancy), the more you are prepared for
                                  it.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      data-widget-id="wid_1595848892_6w0dg6spc"
                      className="moto-widget moto-widget-button moto-preset-default moto-preset-provider-default moto-align-center moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto  "
                      data-widget="button"
                    >
                      <a
                        href="blog/index.html"
                        data-action="blog.index"
                        className="moto-widget-button-link moto-size-medium moto-link"
                      >
                        <span className="fa moto-widget-theme-icon" />
                        <span className="moto-widget-button-divider" />
                        <span className="moto-widget-button-label">View all</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            
           
          </section>
        </div>
        <Footer />
      
      
      </body>
{/* hhhhh */}


    </>
  )
}

export default Surrogatedatabase
